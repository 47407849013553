import React, { useEffect, useRef, useState } from "react";
import "../styles/Create.css";
import axios from "axios";
import { LuArrowLeftFromLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

function Images() {
  useEffect(() => {
    setYo(true);
  }, []);
  const navigate = useNavigate();
  const [yoo, setYoo] = useState(false);
  const [yo, setYo] = useState(false);
  const [sending, setSend] = useState(false);

  const img_url = useRef(null);
  const post_it = async () => {
    const data = {
      img_src: img_url.current.value,
    };
    if (data.img_src.length < 2) setYoo(true);
    else {
      setSend(true);
      await axios.post("https://hyper-dim-2-1.onrender.com/post_image", data);
      setTimeout(() => {
        navigate("/explore");
      }, 200);
    }
  };
  const back_to_home = () => {
    navigate("/");
  };
  return (
    <>
      <section className={`loader ${sending ? "active" : ""}`}>
        <p>
          Your image is being sent. It may take a while if the server is
          experiencing high traffic.
        </p>
      </section>
      <section className={`special_an ${yo ? "active" : ""}`}>
        <header className="title_">
          <div className="something">
            <h2>Shaing Image</h2>
            <p>Hyper Dimension</p>
          </div>
          <LuArrowLeftFromLine
            onClick={back_to_home}
            style={{ cursor: "pointer" }}
          />
        </header>
        <p className="instruction">Please provide a valid image url.</p>
        <section className="create_box">
          <section className="form image">
            <div className="all_side">
              <input type="text" placeholder="Image url" ref={img_url} />
            </div>
            <p className={`error ${yoo ? "active" : ""}`}>
              Image url cannot be empty.
            </p>
            <button onClick={post_it}>share</button>
          </section>
          <section className="create_"></section>
        </section>
      </section>
    </>
  );
}

export default Images;
