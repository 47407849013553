import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function Research({ author, img_src }) {
  const navigate = useNavigate();
  const back_to_explore = () => {
    navigate("/explore");
  };
  return (
    <section className="research">
      <div id="image_box">
        <img src={img_src} alt="" />
      </div>
      <header className="title">
        <h2>{author}</h2>
      </header>
      <button onClick={back_to_explore}>
        <FaArrowRightLong />
      </button>
    </section>
  );
}

export default Research;
