import React, { useEffect, useState } from "react";
import "../styles/Researches.css";
import Research from "./Research";
import axios from "axios";

function Best() {
  const [researches, setResearch] = useState([]);
  const [sending, setSend] = useState(false);
  const [yoo, setYoo] = useState(false);
  useEffect(() => {
    const fetch_researches = async () => {
      const response = await axios.get(
        "https://hyper-dim-2-1.onrender.com/fetch_researches"
      );
      setSend(true);
      setYoo(true);
      setResearch(response.data);
    };
    fetch_researches();
  }, []);
  return (
    <>
      <section className={`loader ${!sending ? "active" : ""}`}>
        <p>
          Please wait while it loads. This may take a while if the server is
          inactive or experiencing high traffic.
        </p>
      </section>
      <section className="best_researches">
        <header className="title">
          <h2>Best researches</h2>
        </header>
        <section className={`researches special_an ${yoo ? "active" : ""}`}>
          {researches.map((elem, index) => (
            <Research
              author={elem.author}
              img_src={elem.img_src}
              key={index}
            ></Research>
          ))}
        </section>
      </section>
    </>
  );
}

export default Best;
