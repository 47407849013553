import React from "react";
import "./styles/Main.css";
import ExHome from "./components/ExHome";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Create from "./components/Create";
import ScrollToTop from "./components/ScrollToTop";
import Images from "./components/Image";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/explore" element={<ExHome />}></Route>
        <Route path="/create" element={<Create />}></Route>
        <Route path="/image" element={<Images />}></Route>
        <Route path="/" element={<Home />}></Route>
      </Routes>
    </>
  );
}

export default App;
