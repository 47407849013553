import React, { useEffect, useState } from "react";
import "../styles/About.css";

function About() {
  const [yo, setYo] = useState(false);
  useEffect(() => {
    setYo(true);
  }, []);
  return (
    <section className={`about special_an ${yo ? "active" : ""}`}>
      <section className="about_">
        <img
          src="https://images-assets.nasa.gov/image/S84-27562/S84-27562~orig.jpg"
          alt=""
        />
        <p>
          This is a place for everyone curious about space. Here, you can
          explore a wide collection of space research and high-quality 4K
          images, all shared by people like you. Whether you're here to add your
          own research or enjoy others' work, our community is all about making
          space knowledge open and accessible to everyone. Let's learn and
          discover together.
        </p>
      </section>
    </section>
  );
}

export default About;
