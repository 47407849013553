import React from "react";
import "../styles/Main.css";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function AccountInstruction() {
  const navigate = useNavigate();
  const back_to_home = () => {
    navigate("/create");
  };
  const back_to_image = () => {
    navigate("/image");
  };

  return (
    <section className="account_ins">
      <section className="account_ins_">
        <p>
          Share your research and images with our community. Let's inspire each
          other with new discoveries and amazing space views.
        </p>
        <div className="buttons">
          <div className="btn">
            <button onClick={back_to_home}>Research</button>
            <button onClick={back_to_image}>Image</button>
          </div>
          <span className="icon_">
            <MdOutlineStarPurple500 />
          </span>
        </div>
      </section>
    </section>
  );
}

export default AccountInstruction;
