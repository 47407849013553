import React from "react";

function Photo({ img_src }) {
  return (
    <section className="photo_bx">
      <img src={img_src} alt="" />
    </section>
  );
}

export default Photo;
