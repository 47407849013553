import React, { useEffect, useState } from "react";
import Photograph from "./Photograph";
import "../styles/Photographs.css";
import axios from "axios";

function Photographs() {
  const [photographs, setPhotographs] = useState([]);
  const [yo, setYo] = useState(false);
  useEffect(() => {
    const fetch_photos = async () => {
      const response = await axios.get(
        "https://hyper-dim-2-1.onrender.com/fetch_photos"
      );
      setYo(true);
      setPhotographs(response.data);
    };
    fetch_photos();
  }, []);
  return (
    <section className={`photographs special_an ${yo ? "active" : ""}`}>
      <header className="title">
        <h2>Best photographs</h2>
      </header>
      <section className="photographs_box">
        {photographs.map((elem, index) => {
          return (
            <Photograph name={elem.name} img_src={elem.img_src} key={index} />
          );
        })}
      </section>
    </section>
  );
}

export default Photographs;
