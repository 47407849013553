import React from "react";
import Logo from "./Logo";
import Insight from "./Insight";
import Best from "./Best";
import Photographs from "./Photographs";
import About from "./About";
import AccountInstruction from "./AccountInstruction";
import Footer from "./Footer";

function Home() {
  return (
    <>
      <Logo />
      <Insight />
      <Best />
      <Photographs />
      <About />
      <AccountInstruction />
      <Footer />
    </>
  );
}

export default Home;
