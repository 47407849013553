import React from "react";

function Exbox({ img_src, heading, research, author }) {
  return (
    <section className="bx">
      <div className="img_box">
        <img src={img_src} alt="" />
      </div>
      <section className="content">
        <h2>{heading}</h2>
        <p>{research}</p>
        <div className="author">
          <p>~ {author}</p>
        </div>
      </section>
    </section>
  );
}

export default Exbox;
