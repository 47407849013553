import React, { useEffect, useRef, useState } from "react";
import "../styles/Create.css";
import axios from "axios";
import { LuArrowLeftFromLine } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

function Create() {
  const navigate = useNavigate();
  const [yoo, setYoo] = useState(false);
  const [yo, setYo] = useState(false);
  const [sending, setSend] = useState(false);
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setYo(true);
  }, []);
  const heading = useRef(null);
  const img_url = useRef(null);
  const research = useRef(null);
  const author = useRef(null);
  const post_it = async () => {
    const data = {
      heading: heading.current.value,
      img_src: img_url.current.value,
      research: research.current.value,
      author: author.current.value,
    };
    console.log(data.heading.length);
    if (
      data.heading.length < 2 ||
      data.img_src.length < 2 ||
      data.research.length < 2 ||
      data.author.length < 2
    )
      setYoo(true);
    else {
      setSend(true);
      await axios.post(
        "https://hyper-dim-2-1.onrender.com/post_research",
        data
      );
      setTimeout(() => {
        navigate("/explore");
      }, 200);
    }
  };
  const back_to_home = () => {
    navigate("/");
  };
  return (
    <>
      <section className={`loader ${sending ? "active" : ""}`}>
        <p>
          Your research is being sent. It may take a while if the server is
          experiencing high traffic.
        </p>
      </section>
      <section className={`special_an ${yo ? "active" : ""}`}>
        <header className="title_">
          <div className="something">
            <h2>Research creation</h2>
            <p>Hyper Dimension</p>
          </div>
          <LuArrowLeftFromLine
            onClick={back_to_home}
            style={{ cursor: "pointer" }}
          />
        </header>
        <p className="instruction">
          All submissions go through a review process to ensure they meet our
          quality and relevance standards. If your work requires revisions,
          we’ll get in touch with feedback.
        </p>
        <section className="create_box">
          <section className="form">
            <div className="all_side">
              <input
                type="text"
                placeholder="Research heading, ex: Black Hole"
                ref={heading}
              />
              <input type="text" placeholder="Image url" ref={img_url} />
              <textarea
                placeholder="Explain the research"
                ref={research}
              ></textarea>

              <input
                type="text"
                placeholder="Author, ex: Shaikali"
                ref={author}
              />
            </div>
            <p className={`error ${yoo ? "active" : ""}`}>
              All fields should must have value.
            </p>
            <button onClick={post_it}>share</button>
          </section>
          <section className="create_"></section>
        </section>
      </section>
    </>
  );
}

export default Create;
