import React from "react";
import "../styles/Logo.css";

function Logo() {
  return (
    <header className="main_logo">
      <h2>Space.</h2>
    </header>
  );
}

export default Logo;
