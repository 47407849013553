import React, { useEffect, useState } from "react";
import "../styles/Insight.css";
import { MdOutlineStarPurple500 } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Insight() {
  const [yo, setYo] = useState(false);
  const [researchesCount, setRCount] = useState(0);
  const [photosCount, setPCount] = useState(0);
  useEffect(() => {
    const fetchR = async () => {
      const fetchedR = await axios.get(
        "https://hyper-dim-2-1.onrender.com/fetch_researches"
      );
      const fetchedP = await axios.get(
        "https://hyper-dim-2-1.onrender.com/fetch_photos"
      );

      setRCount(fetchedR.data.length);
      setPCount(fetchedP.data.length);
      setYo(true);
    };
    fetchR();
  }, []);
  const navigate = useNavigate();
  const navigate_him = () => {
    navigate("/explore");
  };
  return (
    <section className={`insight special_an ${yo ? "active" : ""}`}>
      <section className="insight_">
        <section className="box">
          <section className="top_">
            <h2>
              <span className="special">{researchesCount} </span>
              Researches.
            </h2>
            <h2>
              <span className="special">{photosCount} </span>
              Photographs.
            </h2>
          </section>
          <section className="icon">
            <h2>
              <MdOutlineStarPurple500 />
            </h2>
          </section>
        </section>
        <section className="bottom_">
          <section className="me">
            <p>Everything by ShaikAli.</p>
          </section>
          <section className="button">
            <button onClick={navigate_him}>Explore</button>
          </section>
        </section>
        <div id="image">
          <img
            src="https://media.istockphoto.com/id/537689682/photo/the-milky-way-star-scape.webp?a=1&b=1&s=612x612&w=0&k=20&c=INNqq-_W4c8HIAiJd7Qz15s1oMZb0MyrqPGOHkuy6FY="
            alt=""
          />
        </div>
      </section>
      <div id="image_box">
        <img
          src="https://cdn.esahubble.org/archives/images/screen/opo0328a.jpg"
          alt=""
        />
      </div>
    </section>
  );
}

export default Insight;
