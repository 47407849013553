import React, { useEffect, useState } from "react";
import "../styles/Exhome.css";
import "./Ex_Box";
import Exbox from "./Ex_Box";
import Photo from "./Photo";
import { LuArrowLeftFromLine } from "react-icons/lu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

function ExHome() {
  const [cl, setCl] = useState(false);
  const navigate = useNavigate();
  const [researchess, setResearches] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [yo, setYo] = useState(false);
  useEffect(() => {
    const fetch_researches = async () => {
      const response = await axios.get(
        "https://hyper-dim-2-1.onrender.com/fetch_researches"
      );
      setResearches(response.data);
      setCl(true);
      const photoss = await axios.get(
        "https://hyper-dim-2-1.onrender.com/fetch_photos"
      );
      setPhotos(photoss.data);
      setYo(true);
    };
    fetch_researches();
  }, []);
  const back_to_home = () => {
    navigate("/");
  };
  return (
    <>
      <section className={`loader ${!cl ? "active" : ""}`}>
        <p>
          Please wait while it loads. This may take a while if the server is
          inactive or experiencing high traffic.
        </p>
      </section>
      <section className={`special_an ${yo ? "active" : ""}`}>
        <header className="exh_title">
          <h2>Explore</h2>
          <LuArrowLeftFromLine onClick={back_to_home} />
        </header>
        <section className="exhome">
          <section className="ex_researches">
            <header className="header r">
              <h2>Researches</h2>
            </header>
            <section className="boxes">
              {researchess.map((elem, index) => {
                return (
                  <Exbox
                    img_src={elem.img_src}
                    heading={elem.heading}
                    author={elem.author}
                    research={elem.research}
                    key={index}
                  />
                );
              })}
            </section>
          </section>{" "}
          <section className="ex_researches">
            <header className="header">
              <h2>Photographs</h2>
            </header>
            <section className="boxes">
              {photos.map((elem, index) => (
                <Photo img_src={elem.img_src} key={index} />
              ))}
            </section>
          </section>
          <section className="ex_photographs"></section>
        </section>
      </section>
      <Footer />
    </>
  );
}

export default ExHome;
