import React from "react";
import "../styles/Photographs.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function Photograph({ img_src }) {
  const navigate = useNavigate();
  const to_explore = () => {
    navigate("/explore");
  };
  return (
    <section className="photograph">
      <div id="image_box">
        <img src={img_src} alt="" />
      </div>
      <p>Nebula sky</p>
      <button onClick={to_explore}>
        <FaArrowRightLong />
      </button>
    </section>
  );
}

export default Photograph;
