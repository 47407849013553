import React from "react";
import "../styles/Footer.css";

function Footer() {
  const insta = () => {
    window.location.href = "https://instagram.com/strave_codes";
  };
  return (
    <footer>
      <h2 onClick={insta}>Shaik Ali.</h2>
      <p>プログラマー</p>
    </footer>
  );
}

export default Footer;
